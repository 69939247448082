import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { AppDispatch } from '@store/store';
import { ChevronLeft } from 'react-feather';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import { useTranslation } from 'react-i18next';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import SelectedOptions from '@src/types/SelectedOptions';
import { successToast } from '@src/components/wrappers/ToastMessages';
import RequestStatus from '@src/types/RequestStatus';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import EvaluationGroupSubmitData from '../types/EvaluationGroupSubmitData';
import {
  createNewEvaluationGroup,
  editEvaluationGroup,
  getEvaluationGroupById,
  getEvaluationTypes,
  setSelectedEvaluationTypes,
  setSelectedGroups,
} from '../store';
import EvaluationMetricItems from '../components/EvaluationMetricItems';
import EvaluationGroup from '../types/EvaluationGroup';
import EvaluationGradeItems from '../components/EvaluationGradeItems';
import { evaluationGroupValidationSchema } from '../validation';
import EvaluationState from '../types/EvaluationState';

const EvaluationGroupManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const evaluationsStore = useSelector((store: EvaluationState) => store.evaluations);
  const { t } = useTranslation();
  const { evaluationGroupId } = useParams();
  const navigate = useNavigate();
  const allTenantGroups = useSelector(
    (state: TenantGroupState) => state.tenantGroups.allTenantGroups,
  );

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<EvaluationGroupSubmitData>({
    defaultValues: {
      name: '',
      description: '',
      groupIds: [],
      evaluationType: null as unknown as SelectedOptions,
    },
    resolver: yupResolver(
      evaluationGroupValidationSchema(),
    ),
  });

  useEffect(() => {
    dispatch(getAllTenantGroups());
    dispatch(getEvaluationTypes());
    if (evaluationGroupId) dispatch(getEvaluationGroupById(Number(evaluationGroupId)));
  }, []);

  useEffect(() => {
    if (evaluationsStore.currentEvaluationGroup.id) {
      reset({
        name: evaluationsStore.currentEvaluationGroup.name,
        description: evaluationsStore.currentEvaluationGroup.description,
        groupIds: evaluationsStore.currentEvaluationGroup.tenantGroups
          .map((it) => ({ label: it.name, value: it.id })),
        evaluationType: {
          label: t(`${evaluationsStore.currentEvaluationGroup.evaluationType}`),
          value: evaluationsStore.currentEvaluationGroup.evaluationType,
        },
      });
    }
  }, [evaluationsStore.currentEvaluationGroup]);

  useEffect(() => {
    dispatch(setSelectedEvaluationTypes(evaluationsStore.evaluationTypes.map(
      (item) => ({ label: t(String(item)), value: item }),
    )));
  }, [evaluationsStore.evaluationTypes]);

  useEffect(() => {
    dispatch(setSelectedGroups(allTenantGroups.map(
      (group) => ({ label: group.name, value: group.id }),
    )));
  }, [allTenantGroups]);

  const handleSuccessfulSubmit = async (data: any) => {
    data.evaluationType = data.evaluationType.value;
    data.groupIds = data.groupIds.map((val: SelectedOptions) => String(val.value));
    if (evaluationsStore.currentEvaluationGroup.id) {
      dispatch(editEvaluationGroup(
        { id: evaluationsStore.currentEvaluationGroup.id, data },
      )).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('Evaluation successfully edited'));
          dispatch(getEvaluationGroupById(Number(evaluationGroupId)));
        }
      });
    } else {
      dispatch(createNewEvaluationGroup(data)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('Evaluation successfully added'));
          const { data: resData } = res.payload as { data: EvaluationGroup };
          navigate(`/evaluation-groups/edit/${resData.id}`);
        }
      });
    }
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="outline"
              outline
              style={{
                margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
              }}
              onClick={() => navigate(-1)}
            >
              <ChevronLeft size={21} />
            </Button>
            <CardTitle>{evaluationGroupId ? t('Edit evaluation') : t('Add new evaluation')}</CardTitle>
          </div>
        </CardHeader>
        <CardBody style={{ paddingTop: '1rem' }}>
          <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
            <Row>
              <Col md={4}>
                <CustomLabel name={t('Name')} required />
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input invalid={!!errors.name} id="name" {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.name?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Type')} required />
                <Controller
                  name="evaluationType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="react-select"
                      classNamePrefix="select"
                      defaultValue={null}
                      placeholder={t('Select')}
                      options={evaluationsStore.selectedEvaluationTypes}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.evaluationType?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Groups')} required />
                <Controller
                  name="groupIds"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="react-select"
                      isMulti
                      classNamePrefix="select"
                      options={evaluationsStore.selectedGroups}
                      placeholder={t('Select')}
                      {...field}
                      value={field.value || []}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.groupIds?.message} />
              </Col>
              <Col md={12}>
                <CustomLabel name={t('Description')} />
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Input id="description" type="textarea" invalid={!!errors.description} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.description?.message} />
              </Col>
              <Col md={4} />
              <Col md={4}>
                <CustomLabel style={{ opacity: 0 }} name={t('Submit')} />
                <Button
                  type="submit"
                  color="primary"
                  disabled={false}
                  style={{ minWidth: '100%' }}
                >
                  {evaluationGroupId ? t('Save') : t('Add')}
                </Button>
              </Col>
            </Row>
          </Form>
          { evaluationGroupId ? (
            <>
              <Col md={12} style={{ paddingTop: '16px' }}><h6>{t('Items')}</h6></Col>
              <EvaluationMetricItems evaluationGroupId={evaluationGroupId} />
            </>
          ) : <></>}
          {(evaluationGroupId && watch('evaluationType.value') !== 'YEARLY_EVALUATIONS') ? (
            <>
              <Col md={12} style={{ paddingTop: '16px' }}><h6>{t('Grades')}</h6></Col>
              <EvaluationGradeItems evaluationGroupId={evaluationGroupId} />
            </>
          ) : <></>}
        </CardBody>
      </Card>
    </div>
  );
};

export default EvaluationGroupManagement;
