import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import SelectedOptions from '@src/types/SelectedOptions';
import EvaluationGroup from '../types/EvaluationGroup';
import EvaluationGroupSubmitData from '../types/EvaluationGroupSubmitData';
import EvaluationMetric from '../types/EvaluationMetric';
import EvaluationMetricSubmitData from '../types/EvaluationMetricSubmitData';
import EvaluationGrade from '../types/EvaluationGrade';
import EvaluationGradeSubmitData from '../types/EvaluationGradeSubmitData';
import Evaluation from '../types/Evaluation';
import EvaluationSubmitData from '../types/EvaluationSubmitData';

export const getAllEvaluations = createAsyncThunk('goKinder/getAllEvaluations', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/evaluations${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getEvaluationById = createAsyncThunk('goKinder/getEvaluationById', async (id: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/evaluations/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const createNewEvaluation = createAsyncThunk('goKinder/createNewEvaluation', async (data: EvaluationSubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/evaluations`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editEvaluation = createAsyncThunk('goKinder/editEvaluation', async ({ id, data } :{id: number, data: EvaluationSubmitData}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/evaluations/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteEvaluation = createAsyncThunk('goKinder/deleteEvaluation', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/evaluations/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getAllEvaluationGroups = createAsyncThunk('goKinder/getAllEvaluationGroups', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/evaluation-groups${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getEvaluationGroupById = createAsyncThunk('goKinder/getEvaluationGroupById', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/evaluation-groups/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const createNewEvaluationGroup = createAsyncThunk('goKinder/createNewEvaluationGroup', async (data: EvaluationGroupSubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/evaluation-groups`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editEvaluationGroup = createAsyncThunk('goKinder/editEvaluationGroup', async ({ id, data } :{id: number, data: EvaluationMetric}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/evaluation-groups/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteEvaluationGroup = createAsyncThunk('goKinder/deleteEvaluationGroup', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/evaluation-groups/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getEvaluationTypes = createAsyncThunk('goKinder/getEvaluationTypes', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/evaluations/type-of-evaluations`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getAllEvaluationMetrics = createAsyncThunk('goKinder/getAllEvaluationMetrics', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/evaluation-metrics${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const createNewEvaluationMetric = createAsyncThunk('goKinder/createNewEvaluationMetric', async (data: EvaluationMetricSubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/evaluation-metrics`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editEvaluationMetrics = createAsyncThunk('goKinder/editEvaluationMetrics', async ({ id, data } :{id: number, data: EvaluationMetric}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/evaluation-metrics/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteEvaluationMetric = createAsyncThunk('goKinder/deleteEvaluationMetric', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/evaluation-metrics/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getAllEvaluationGrades = createAsyncThunk('goKinder/getAllEvaluationGrades', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/evaluation-grades${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const createNewEvaluationGrades = createAsyncThunk('goKinder/createNewEvaluationGrades', async (data: EvaluationGradeSubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/evaluation-grades`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editEvaluationGrade = createAsyncThunk('goKinder/editEvaluationGroup', async ({ id, data } :{id: number, data: EvaluationGrade}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/evaluation-grades/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteEvaluationGrade = createAsyncThunk('goKinder/deleteEvaluationGroup', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/evaluation-grades/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const evaluationGroupsSlice = createSlice({
  name: 'evaluations',
  initialState: {
    allEvaluations: [] as Evaluation[],
    currentEvaluation: {} as Evaluation,
    allEvaluationGroups: [] as EvaluationGroup[],
    allEvaluationMetrics: [] as EvaluationMetric[],
    allEvaluationGrades: [] as EvaluationGrade[],
    currentEvaluationGroup: {} as EvaluationGroup,
    evaluationTypes: [] as string[],
    selectedYears: [] as SelectedOptions[],
    selectedEvaluationTypes: [] as SelectedOptions[],
    selectedGroups: [] as SelectedOptions[],
  },
  reducers: {
    setAllEvaluations: (state, action) => {
      state.allEvaluations = action.payload;
    },
    setAllEvaluationGroups: (state, action) => {
      state.allEvaluationGroups = action.payload;
    },
    setAllEvaluationMetrics: (state, action) => {
      state.allEvaluationMetrics = action.payload;
    },
    setAllEvaluationGrades: (state, action) => {
      state.allEvaluationGrades = action.payload;
    },
    setSelectedEvaluationTypes: (state, action) => {
      state.selectedEvaluationTypes = action.payload;
    },
    setSelectedGroups(state, action) {
      state.selectedGroups = action.payload;
    },
    setSelectedYears(state, action) {
      state.selectedYears = action.payload;
    },
    clearCurrentEvaluation: (state) => {
      state.currentEvaluation = {} as Evaluation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllEvaluations.fulfilled, (state, action) => {
      state.allEvaluations = action.payload.data;
    });
    builder.addCase(getEvaluationById.fulfilled, (state, action) => {
      state.currentEvaluation = action.payload.data;
    });
    builder.addCase(getAllEvaluationGroups.fulfilled, (state, action) => {
      state.allEvaluationGroups = action.payload.data;
    });
    builder.addCase(getEvaluationGroupById.fulfilled, (state, action) => {
      state.currentEvaluationGroup = action.payload.data;
    });
    builder.addCase(getAllEvaluationGrades.fulfilled, (state, action) => {
      state.allEvaluationGrades = action.payload.data;
    });
    builder.addCase(getEvaluationTypes.fulfilled, (state, action) => {
      state.evaluationTypes = action.payload.data.typeOfEvaluationsEnum;
    });
    builder.addCase(getAllEvaluationMetrics.fulfilled, (state, action) => {
      state.allEvaluationMetrics = action.payload.data;
    });
  },
});

export const {
  setAllEvaluationGroups,
  setAllEvaluationMetrics,
  setAllEvaluationGrades,
  setAllEvaluations,
  setSelectedEvaluationTypes,
  setSelectedGroups,
  clearCurrentEvaluation,
  setSelectedYears,
} = evaluationGroupsSlice.actions;

export default evaluationGroupsSlice.reducer;
