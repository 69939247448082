import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col, Form, Row,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import StoreState from '@store/StoreState';
import { AppDispatch } from '@store/store';
import RequestStatus from '@src/types/RequestStatus';
import FormHeader from '@src/components/forms/FormHeader';
import { useTranslation } from 'react-i18next';
import Switcher from '@src/components/switchers/Switcher';
import toast from 'react-hot-toast';
import AddParentValidationSchema from '../validation/AddParentValidationSchema';
import {
  addParent, createUserAccountForParent, editParentInfo, getParentById,
} from '../store';

import User from '../types/User';
import EditParentValidationSchema from '../validation/EditParentValidationSchema';
import EditableFields from './form/EditableFields';

const ParentManagement = () => {
  const store = useSelector((state: StoreState) => state.parents);
  const { parentId } = useParams();
  const navigate = useNavigate();
  const defaultValues = store.currentParent ?? {
    embg: '',
    contractNumber: '',
    bankAccountNumber: '',
    address: '',
    city: '',
    phoneNumber: '',
    name: '',
    surname: '',
    email: '',
    password: '',
  };

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [createAccount, setCreateAccount] = useState(false);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: store.currentParent ? Object.fromEntries(
      Object.entries(defaultValues).map(([key, value]) => [key, value ?? '']),
    ) as User : defaultValues,
    resolver: yupResolver(
      store?.currentParent
        ? EditParentValidationSchema(store?.allParents, store?.currentParent)
        : AddParentValidationSchema(store?.allParents),
    ),
  });

  const handleSuccessfulSubmit: SubmitHandler<User> = (values) => {
    if (parentId) {
      const {
        id,
        name,
        surname,
        email,
        embg,
        idNumber,
        externalId,
        bankAccountNumber,
        contractNumber,
        address,
        city,
        phoneNumber,
      } = { ...values };
      dispatch(editParentInfo({
        id,
        name,
        surname,
        email,
        embg,
        idNumber,
        externalId,
        bankAccountNumber,
        contractNumber,
        address,
        city,
        phoneNumber,
      })).then(
        (res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            navigate(-1);
          }
        },
      );
    } else {
      dispatch(addParent({ ...values, createUserAccount: createAccount ? '1' : '0' })).then(
        (res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            navigate(-1);
          }
        },
      );
    }
  };

  const handleCreateAccountClick = () => {
    if (parentId) {
      dispatch(createUserAccountForParent(parentId)).then(
        (res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Account successfully created'), { position: 'top-right', duration: 3000 });
            dispatch(getParentById(parentId));
          }
        },
      );
    }
  };

  useEffect(() => {
    if (parentId) {
      dispatch(getParentById(parentId));
    }
  }, []);

  useEffect(() => {
    reset(store.currentParent);
  }, [store.currentParent]);

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <FormHeader
        title={parentId ? t('Update parent information') : t('Add parent')}
        subtitle={parentId ? t('Check and edit parent details') : t('Please enter parent details')}
      />
      <Card>
        <CardTitle className="p-1 mb-0">
          {t('Basic information')}
        </CardTitle>
        <CardBody>
          <Row>
            <EditableFields control={control} errors={errors} />
            {!store.currentParent.id ? (
              <Col md={4}>
                <Switcher id="contractHolder" checked={createAccount} onChange={() => setCreateAccount(!createAccount)} />
                {t('Create account for a parent')}
              </Col>
            ) : <></>}
          </Row>
          <p className="text-muted">
            {t('Fields marked with')}&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;{t('are required')}
          </p>
          <Row className="gy-1 gx-2 mt-75">
            <Col className="text-center mt-1" xs={12}>
              <Button type="submit" className="me-1" color="primary" disabled={!isDirty}>
                {parentId ? t('Save changes') : t('Add')}
              </Button>
              {(!store.currentParent.userId && store.currentParent.id) ? (
                <Button className="me-1" color="primary" onClick={() => handleCreateAccountClick()} outline>
                  {t('Create account for a parent')}
                </Button>
              ) : <></>}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
};

export default ParentManagement;
