import {
  Card, CardBody, CardHeader, CardSubtitle, CardTitle,
  Col,
  Progress,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  subtitle?: string;
  button?: JSX.Element;
  className?: string;
  maxNumberOfChildren: number
  activeChildren: number;
}

const TenantFormHeader: React.FC<Props> = ({
  title, subtitle, button, className = '', maxNumberOfChildren, activeChildren,
}) => {
  const { t } = useTranslation();
  return (
    <Card className={className}>
      <CardHeader className="d-flex">
        <div>
          <CardTitle tag="h4">{title}</CardTitle>
          <CardSubtitle className="mt-1">{subtitle}</CardSubtitle>
        </div>
        {button}
      </CardHeader>
      <CardBody>
        <Col md={4}>
          <CustomLabel name={t('Max Number Of Children')} />
          <Col
            md={12}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              margin: 0,
              fontSize: '12px',
            }}
          >
            <div>0</div><div>{maxNumberOfChildren}</div>
          </Col>
          <Col md={12}><Progress style={{ height: '16px' }} min={0} max={maxNumberOfChildren} value={activeChildren} aria-label="Max number of children">{activeChildren}</Progress></Col>
        </Col>
      </CardBody>
    </Card>
  );
};

export default TenantFormHeader;
