import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import SelectedOptions from '@src/types/SelectedOptions';
import { successToast } from '@src/components/wrappers/ToastMessages';
import RequestStatus from '@src/types/RequestStatus';
import { closeModal } from '@store/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createNewUser,
  editUser,
  getAllUsers,
} from '../store';
import UserState from '../types/UserState';
import User from '../types/User';
import userValidationSchema from '../validation';

type UserFormData = {
  name: string;
  surname: string;
  workplace: string;
  phoneNumber: string;
  email: string;
  objects: SelectedOptions[];
  role: SelectedOptions;
}

const UserForm = ({ user }: { user?: User }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userStore = useSelector((store: UserState) => store.users);
  const { t } = useTranslation();
  const systemRoles = new Set(['teacher', 'tenantAdmin']);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserFormData>({
    defaultValues: {
      name: user ? user.name : '',
      surname: user ? user.surname : '',
      workplace: user ? user.workplace : '',
      phoneNumber: user ? user.phoneNumber : '',
      email: user ? user.email : '',
      objects: user ? user.objects
        .map((object) => ({ label: t(String(object.name)), value: object.id })) : [],
      role: user
        ? {
          label: systemRoles.has(user.role.name) ? t(String(user.role.name)) : user.role.name,
          value: user.role.id,
        }
        : null as unknown as SelectedOptions,
    },
    resolver: yupResolver(userValidationSchema(user ? [] : userStore.allUsers)),
  });

  const handleSuccessfulSubmit = async (data: any) => {
    data.roleId = data.role.value;
    delete data.role;
    data.objectIds = data.objects.map((val: SelectedOptions) => String(val.value));
    delete data.objects;
    if (user) {
      dispatch(editUser(
        { id: user.id, data },
      )).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('User successfully edited'));
          dispatch(getAllUsers());
          dispatch(closeModal());
        }
      });
    } else {
      dispatch(createNewUser(data)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('User successfully added'));
          dispatch(getAllUsers());
          dispatch(closeModal());
        }
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={4}>
          <CustomLabel required name={t('Name')} />
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input id="name" invalid={!!errors.name} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.name?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel name={t('Surname')} required />
          <Controller
            control={control}
            name="surname"
            render={({ field }) => (
              <Input invalid={!!errors.surname} id="surname" {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.surname?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel name={t('Email')} required />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input invalid={!!errors.email} id="email" {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.email?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel name={t('Phone number')} />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <Input invalid={!!errors.phoneNumber} id="phoneNumber" {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.phoneNumber?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel name={t('Workplace')} />
          <Controller
            control={control}
            name="workplace"
            render={({ field }) => (
              <Input invalid={!!errors.workplace} id="workplace" {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.workplace?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel name={t('Objects')} required />
          <Controller
            name="objects"
            control={control}
            render={({ field }) => (
              <Select
                className="react-select"
                isMulti
                classNamePrefix="select"
                options={userStore.selectedObjects}
                placeholder={t('Select')}
                {...field}
                value={field.value || []}
              />
            )}
          />
          <CustomFormFeedback message={errors?.objects?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel name={t('Role')} required />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className="react-select"
                classNamePrefix="select"
                defaultValue={null}
                placeholder={t('Select')}
                options={userStore.selectedRoles}
              />
            )}
          />
          <CustomFormFeedback message={errors?.role?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel style={{ opacity: 0 }} name={t('Submit')} />
          <Button
            type="submit"
            color="primary"
            disabled={false}
            style={{ minWidth: '100%' }}
          >
            {user ? t('Save') : t('Add')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
