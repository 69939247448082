import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import axios from 'axios';
import Contract from '../types/Contract';

export const getAllContracts = createAsyncThunk('goKinder/getAllContracts', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/contracts${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const addNewContract = createAsyncThunk('goKinder/addNewContract', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/contracts`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editContract = createAsyncThunk('goKinder/editContract', async ({ id, data }: { id: number, data: any }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/contracts/${id}`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteContract = createAsyncThunk('goKinder/deleteContract', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/contracts/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const contractSlice = createSlice({
  name: 'contract',
  initialState: {
    allContracts: [] as Contract[],
  },
  reducers: {
    clearContracts(state) {
      state.allContracts = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllContracts.fulfilled, (state, action) => {
      state.allContracts = action.payload.data;
    });
  },
});

export const {
  clearContracts,
} = contractSlice.actions;

export default contractSlice.reducer;
