import Packages from '@src/views/packages';
import Children from '@src/views/children/pages';
import Polls from '@src/views/polls/pages';
import Notes from '@src/views/notes/pages';
import PollStatistics from '@src/views/polls/pages/statistics';
import AllPosts from '@src/views/posts';
import TenantGroups from '@src/views/tenantGroups';
import TenantObjects from '@src/views/tenantObjects';
import { lazy } from 'react';
import ChildManagement from '@src/views/children/pages/child-management';
import ChildProfile from '@src/views/children/pages/child-profile';
import ParentChildRelationship from '@src/views/children/pages/parent-child-relationship';
import Sso from '@src/views/authentication/Sso';
import Absences from '@src/views/absences/pages';
import General from '@src/views/tenants/pages/general';
import Albums from '@src/views/albums/pages/Albums';
import AlbumForm from '@src/views/albums/pages/AlbumForm';
import NonWorkingDays from '@src/views/nonNonWorkingDays/pages';
import AlbumPreview from '@src/views/albums/pages/AlbumPreview';
import AddFilesForm from '@src/views/albums/pages/AddFilesForm';
import Announcements from '@src/views/announcements/pages';
import InvoiceManagement from '@src/views/invoice/pages/InvoiceManagement';
import InvoicePreview from '@src/views/invoice/pages/InvoicePreview';
import InvoiceReport from '@src/views/invoice/pages/InvoiceReport';
import Payments from '@src/views/payments/pages';
import InvoiceBulkAdd from '@src/views/invoice/pages/InvoiceBulkAdd';
import ParentManagement from '@src/views/parents/components/ParentManagement';
import Evaluations from '@src/views/evaluations/pages';
import EvaluationGroupManagement from '@src/views/evaluations/pages/EvaluationGroupManagement';
import EvaluationsManagement from '@src/views/evaluations/pages/EvaluationsManagement';
import Contracts from '@src/views/contracts/pages';
import Roles from '@src/views/roles/pages';
import Users from '@src/views/users/pages';
import FaqCategories from '@src/views/faq/pages';
import FaqCategoriesManagement from '@src/views/faq/pages/FaqCategoriesManagement';
import Faq from '@src/views/faq/pages/Faq';
import CityForms from '@src/views/cityForms/pages';

const ErrorComponent = lazy(() => import('@views/misc/Error'));
const NotAuthorized = lazy(() => import('@views/misc/InvalidToken'));
const Tenants = lazy(() => import('@tenants/pages/index'));
const TenantManagement = lazy(() => import('@tenants/pages/tenant-management'));
const Parents = lazy(() => import('@parents/index'));
const PasswordManagement = lazy(
  () => import('../../views/authentication/PasswordManagement'),
);

const PagesRoutes = [
  {
    path: '/misc/not-authorized',
    element: <NotAuthorized />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/Tenants',
    element: <Tenants />,
    meta: {
      privateRoute: true,
      permissionName: 'Tenants',
    },
  },
  {
    path: '/parents',
    element: <Parents />,
    meta: {
      privateRoute: true,
      permissionName: 'Parents',
    },
  },
  {
    path: '/parent/:parentId/edit',
    element: <ParentManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Parents',
    },
  },
  {
    path: '/parent/new',
    element: <ParentManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Parents',
    },
  },
  {
    path: '/tenants/:tenantId/edit',
    element: <TenantManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Tenants',
    },
  },
  {
    path: '/posts',
    element: <AllPosts />,
    meta: {
      privateRoute: true,
      permissionName: 'Posts',
    },
  },
  {
    path: '/reset-password/:token',
    element: <PasswordManagement />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/tenants/new-tenant',
    element: <TenantManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Tenants',
    },
  },
  {
    path: '/polls',
    element: <Polls />,
    meta: {
      privateRoute: true,
      permissionName: 'Polls',
    },
  },
  {
    path: '/polls/:id/statistics',
    element: <PollStatistics />,
    meta: {
      privateRoute: true,
      permissionName: 'Polls',
    },
  },
  {
    path: '/notes',
    element: <Notes />,
    meta: {
      privateRoute: true,
      permissionName: 'Notes',
    },
  },
  {
    path: '/announcements',
    element: <Announcements />,
    meta: {
      privateRoute: true,
      permissionName: 'Announcements',
    },
  },
  {
    path: '/objects',
    element: <TenantObjects />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationObjects',
    },
  },
  {
    path: '/general',
    element: <General />,
    meta: {
      privateRoute: true,
      permissionName: 'General',
    },
  },
  {
    path: '/groups',
    element: <TenantGroups />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationGroups',
    },
  },
  {
    path: '/packages',
    element: <Packages />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationPackages',
    },
  },
  {
    path: '/children',
    element: <Children />,
    meta: {
      privateRoute: true,
      permissionName: 'Children',
    },
  },
  {
    path: '/children/new',
    element: <ChildManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Children',
    },
  },
  {
    path: '/children/:childId/edit',
    element: <ChildManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Children',
    },
  },
  {
    path: '/children/:childId',
    element: <ChildProfile />,
    meta: {
      privateRoute: true,
      permissionName: 'Children',
    },
  },
  {
    path: '/children/:childId/parent-relationship',
    element: <ParentChildRelationship />,
    meta: {
      privateRoute: true,
      permissionName: 'Children',
    },
  },
  {
    path: '/children/:childId/parent-relationship/add',
    element: <ParentChildRelationship />,
    meta: {
      privateRoute: true,
      permissionName: 'Children',
    },
  },
  {
    path: '/absences',
    element: <Absences />,
    meta: {
      privateRoute: true,
      permissionName: 'Absences',
    },
  },
  {
    path: '/gallery',
    element: <Albums />,
    meta: {
      privateRoute: true,
      permissionName: 'Gallery',
    },
  },
  {
    path: '/gallery/album/edit/:albumId',
    element: <AlbumForm />,
    meta: {
      privateRoute: true,
      permissionName: 'Gallery',
    },
  },
  {
    path: '/gallery/album/:albumId',
    element: <AlbumPreview />,
    meta: {
      privateRoute: true,
      permissionName: 'Gallery',
    },
  },
  {
    path: '/gallery/album/:albumId/add-files',
    element: <AddFilesForm />,
    meta: {
      privateRoute: true,
      permissionName: 'Gallery',
    },
  },
  {
    path: '/non-working-days',
    element: <NonWorkingDays />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationNonWorkingDays',
    },
  },
  {
    path: '/invoices',
    element: <InvoiceReport />,
    meta: {
      privateRoute: true,
      permissionName: 'Invoices',
    },
  },
  {
    path: '/invoice/:childId/new',
    element: <InvoiceManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Invoices',
    },
  },
  {
    path: '/invoice/edit/:invoiceId',
    element: <InvoiceManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Invoices',
    },
  },
  {
    path: '/invoice/preview/:invoiceId',
    element: <InvoicePreview />,
    meta: {
      privateRoute: true,
      permissionName: 'Invoices',
    },
  },
  {
    path: '/invoice/preview/mobile/:invoiceHash',
    element: <InvoicePreview />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/invoice/bulk/add',
    element: <InvoiceBulkAdd />,
    meta: {
      privateRoute: true,
      permissionName: 'Invoices',
    },
  },
  {
    path: '/payments',
    element: <Payments />,
    meta: {
      privateRoute: true,
      permissionName: 'Payments',
    },
  },
  {
    path: '/evaluations/:childId/new',
    element: <EvaluationsManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Evaluations',
    },
  },
  {
    path: '/evaluations/edit/:evaluationId',
    element: <EvaluationsManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'Evaluations',
    },
  },
  {
    path: '/evaluation-groups',
    element: <Evaluations />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationEvaluations',
    },
  },
  {
    path: '/evaluation-groups/new',
    element: <EvaluationGroupManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationEvaluations',
    },
  },
  {
    path: '/evaluation-groups/edit/:evaluationGroupId',
    element: <EvaluationGroupManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationEvaluations',
    },
  },
  {
    path: '/contracts',
    element: <Contracts />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationContracts',
    },
  },
  {
    path: '/roles',
    element: <Roles />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationRoles',
    },
  },
  {
    path: '/users',
    element: <Users />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationUsers',
    },
  },
  {
    path: '/faq-categories',
    element: <FaqCategories />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationFaqCategories',
    },
  },
  {
    path: '/faq-categories/new',
    element: <FaqCategoriesManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationFaqCategories',
    },
  },
  {
    path: '/faq-categories/edit/:faqCategoryId',
    element: <FaqCategoriesManagement />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationFaqCategories',
    },
  },
  {
    path: '/faq',
    element: <Faq />,
    meta: {
      privateRoute: false,
    },
  },
  {
    path: '/city-forms',
    element: <CityForms />,
    meta: {
      privateRoute: true,
      permissionName: 'AdministrationCityForms',
    },
  },
  {
    path: '/misc/error',
    element: <ErrorComponent />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/auth/sso/:token',
    element: <Sso />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
];

export default PagesRoutes;
