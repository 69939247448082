import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import SelectedOptions from '@src/types/SelectedOptions';
import User from '../types/User';
import UserSubmitData from '../types/UserSubmitData';

export const getAllUsers = createAsyncThunk('goKinder/getAllUsers', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/users${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getUserById = createAsyncThunk('goKinder/getUserById', async (id: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/users/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const createNewUser = createAsyncThunk('goKinder/createNewUser', async (data: UserSubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/users`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editUser = createAsyncThunk('goKinder/editUser', async ({ id, data } : { id: number, data: UserSubmitData }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/users/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteUser = createAsyncThunk('goKinder/deleteUser', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/users/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    allUsers: [] as User[],
    currentUser: {} as User,
    selectedObjects: [] as SelectedOptions[],
    selectedRoles: [] as SelectedOptions[],
  },
  reducers: {
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = {} as User;
    },
    setSelectedObjects: (state, action) => {
      state.selectedObjects = action.payload;
    },
    setSelectedRoles: (state, action) => {
      state.selectedRoles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload.data;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.currentUser = action.payload.data;
    });
  },
});

export const {
  setAllUsers,
  clearCurrentUser,
  setSelectedObjects,
  setSelectedRoles,
} = usersSlice.actions;

export default usersSlice.reducer;
