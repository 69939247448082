import { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import Switcher from '../../../components/switchers/Switcher';

interface Props {
  field: any;
}

const PdvRate: React.FC<Props> = ({ field }) => {
  const [hasPdv, setHasPdv] = useState<boolean>(!!field.value);

  const handleNoPdv = () => {
    setHasPdv(!hasPdv);
    field.onChange(0);
  };

  useEffect(() => {
    setHasPdv(!!field.value);
  }, [field.value]);

  return (
    <div>

      <label htmlFor="pdv" style={{ display: 'flex' }}>
        <Switcher data-testid="mapView" checked={hasPdv} onChange={handleNoPdv} />
        {hasPdv
          && (
          <div style={{ display: 'flex', alignItems: 'center', maxWidth: '120px' }}>
            Rate:
            <Input
              id="pdv"
              type="number"
              {...field}
              style={{ textAlign: 'end', marginLeft: '0.5rem' }}
            />
            %
          </div>
          )}
      </label>
    </div>
  );
};

export default PdvRate;
