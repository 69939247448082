import { useEffect, useMemo, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import CustomLabel from '@src/components/forms/CustomLabel';
import SelectedOptions from '@src/types/SelectedOptions';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import { getAllPackages } from '@src/views/packages/store';
import PackageState from '@src/views/packages/types/PackageState';
import { yupResolver } from '@hookform/resolvers/yup';
import MONTHS from '@src/constants/months';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { useNavigate } from 'react-router-dom';
import InvoiceState from '../types/InvoiceState';
import { formatDateLocalDateString } from '../components/InvoiceColumns';
import {
  getYears,
  selectedYears,
  selectedObjects,
  selectedGroups,
  getMultipleInvoices,
  getInvoiceTypes,
  selectedInvoiceType,
  getInvoiceNumbers,
  selectedPackageIds,
  addMultipleInvoices,
} from '../store';
import InvoiceBulkColumns from '../components/InvoiceBulkColumns';
import { invoiceBulkAddSchema } from '../validation/InvoiceValidation';

type SubmitData = {
  invoiceNumber: number,
  yearId: SelectedOptions,
  invoiceType: SelectedOptions,
  month: SelectedOptions,
  groupIds: SelectedOptions[],
  objectIds: SelectedOptions[],
  packageIds: SelectedOptions[],
  deletedChildren: string[],
}

const InvoiceBulkAdd = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useSelector((state: InvoiceState) => state.invoice);
  const [show, setShow] = useState(false);
  const allTenantObjects = useSelector(
    (state: TenantObjectState) => state.tenantObjects.allTenantObjects,
  );
  const allTenantGroups = useSelector(
    (state: TenantGroupState) => state.tenantGroups.allTenantGroups,
  );
  const AllPackages = useSelector(
    (state: PackageState) => state.packages.allPackages,
  );

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<SubmitData>({
    defaultValues: {
      groupIds: [],
      objectIds: [],
      packageIds: [],
      invoiceNumber: 0,
      yearId: null as unknown as SelectedOptions,
      invoiceType: null as unknown as SelectedOptions,
      month: null as unknown as SelectedOptions,
    },
    resolver: yupResolver(
      invoiceBulkAddSchema(),
    ),
  });

  const transformObjToSelectObject = (objects: { name: string, id: number }[],
    translate = false) => objects.map(
    (obj) => ({ label: translate ? t(obj.name) : obj.name, value: obj.id }),
  );

  useEffect(() => {
    dispatch(getYears());
    dispatch(getAllTenantObjects());
    dispatch(getAllTenantGroups());
    dispatch(getInvoiceTypes());
    dispatch(getInvoiceNumbers());
    dispatch(getAllPackages());
  }, []);

  useEffect(() => {
    dispatch(selectedObjects(transformObjToSelectObject(allTenantObjects)));
  }, [allTenantObjects]);

  useEffect(() => {
    dispatch(selectedGroups(transformObjToSelectObject(allTenantGroups)));
  }, [allTenantGroups]);

  useEffect(() => {
    dispatch(selectedPackageIds(AllPackages.map(
      (item) => ({ label: String(item.name), value: item.id }),
    )));
  }, [AllPackages]);

  useEffect(() => {
    if (store.invoiceNumbers.length) setValue('invoiceNumber', Number(store.invoiceNumbers[0].invoiceNumber));
  }, [store.invoiceNumbers]);

  useEffect(() => {
    dispatch(selectedInvoiceType(store.invoiceTypes.map(
      (type) => ({ label: t(`${type}`), value: type }),
    )));
  }, [store.invoiceTypes]);

  useEffect(() => {
    dispatch(selectedYears(store.years.map(
      (year) => ({ label: String(year.value), value: year.id }),
    )));
  }, [store.years]);

  const handleSuccessfulSubmit = async (data: any) => {
    reset(data);
    let query = '';
    data.invoiceType = data.invoiceType.value;
    data.month = data.month.value;
    data.yearId = data.yearId.value;
    if (data.objectIds) data.objectIds = data.objectIds.map((val: SelectedOptions) => val.value).join(',');
    if (data.groupIds) data.groupIds = data.groupIds.map((val: SelectedOptions) => val.value).join(',');
    if (data.packageIds) data.packageIds = data.packageIds.map((val: SelectedOptions) => val.value).join(',');
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(data)) {
      query += `${key}=${data[key]}&`;
    }
    dispatch(getMultipleInvoices(query));
    setShow(true);
  };

  const months = useMemo(() => MONTHS.map((item, i) => ({ label: t(`${item}`), value: i + 1 })), []);
  const [deletedChildren, setDeletedChildren] = useState<Set<string>>(new Set<string>());

  const handleDeleteClick = (id: number) => {
    if (deletedChildren.has(String(id))) {
      deletedChildren.delete(String(id));
    } else {
      deletedChildren.add(String(id));
    }
    setDeletedChildren(new Set([...deletedChildren]));
  };

  const handleSave = async () => {
    const data: any = getValues();
    data.deletedChildren = [...deletedChildren];
    data.invoiceType = data.invoiceType.value;
    data.month = data.month.value;
    data.yearId = data.yearId.value;
    if (data.objectIds) data.objectIds = data.objectIds.map((val: SelectedOptions) => val.value).join(',');
    if (data.groupIds) data.groupIds = data.groupIds.map((val: SelectedOptions) => val.value).join(',');
    if (data.packageIds) data.packageIds = data.packageIds.map((val: SelectedOptions) => val.value).join(',');
    dispatch(addMultipleInvoices(data)).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        successToast(t('Invoices successfully added'));
        navigate('/invoices');
      }
    });
  };

  useEffect(() => {
    if (store.invoiceNumbers?.length) {
      const nextNumber = Number(store.invoiceNumbers[0].invoiceNumber) + 1;
      setValue('invoiceNumber', nextNumber);
    }
  }, [store.invoiceNumbers]);

  useEffect(() => {
    if (show && isDirty) setShow(false);
  }, [isDirty, show]);

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="mt-2">
            <CardTitle tag="h4">{t('Bulk invoice addition')}</CardTitle>
          </div>
        </CardHeader>
        <CardBody style={{ paddingTop: '1rem' }}>
          <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
            <Row>
              <Col md={4}>
                <CustomLabel name={t('Invoice year')} required />
                <Controller
                  name="yearId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="react-select"
                      classNamePrefix="select"
                      defaultValue={null}
                      options={store.selectedYears}
                      placeholder={t('Select')}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.yearId?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Month')} required />
                <Controller
                  name="month"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="react-select"
                      classNamePrefix="select"
                      defaultValue={null}
                      options={months}
                      placeholder={t('Select')}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.month?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Invoice type')} required />
                <Controller
                  name="invoiceType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="react-select"
                      classNamePrefix="select"
                      options={store.selectedInvoiceType || []}
                      value={field.value || []}
                      placeholder={t('Select')}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.invoiceType?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Invoice number')} required />
                <Controller
                  name="invoiceNumber"
                  control={control}
                  render={({ field }) => (
                    <Input id="invoiceNumber" type="number" invalid={!!errors.invoiceNumber} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.invoiceNumber?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Object')} />
                <Controller
                  name="objectIds"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="react-select"
                      isMulti
                      classNamePrefix="select"
                      options={store.selectedObjects || []}
                      {...field}
                      value={field.value || []}
                      placeholder={t('Select')}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.objectIds?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Groups')} />
                <Controller
                  name="groupIds"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="react-select"
                      isMulti
                      classNamePrefix="select"
                      options={store.selectedGroups || []}
                      {...field}
                      value={field.value || []}
                      placeholder={t('Select')}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.groupIds?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Packages')} />
                <Controller
                  name="packageIds"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="react-select"
                      isMulti
                      classNamePrefix="select"
                      options={store.selectedPackageIds || []}
                      {...field}
                      value={field.value || []}
                      placeholder={t('Select')}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.packageIds?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel style={{ opacity: 0 }} name={t('Submit')} />
                <Button
                  type="submit"
                  color="primary"
                  disabled={false}
                  style={{ minWidth: '100%' }}
                >
                  {t('Generate')}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Card>
        {show && (
          <>
            {store.bulkInvoices.length ? (
              <CardHeader>
                <p style={{ margin: 0 }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Date Issued')}: </span>
                  {formatDateLocalDateString(store.bulkInvoices[0].dateIssued)}
                </p>
                <p style={{ margin: 0 }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Due Date')}: </span>
                  {formatDateLocalDateString(store.bulkInvoices[0].dueDate)}
                </p>
                <p style={{ margin: 0 }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Service Period')}: </span>
                  {formatDateLocalDateString(store.bulkInvoices[0].servicePeriodFrom)}&nbsp;-
                  &nbsp;{formatDateLocalDateString(store.bulkInvoices[0].servicePeriodTo)}
                </p>
                <Button
                  type="button"
                  color="primary"
                  disabled={false}
                  onClick={handleSave}
                  style={{ minWidth: '300px' }}
                >
                  {t('Save')}
                </Button>
              </CardHeader>
            ) : ''}
          </>
        )}
        <CardBody>
          <DataTable
            noHeader
            className="react-dataTable"
            columns={InvoiceBulkColumns({ handleDeleteClick, deletedChildren })}
            progressComponent={<div />}
            noDataComponent={!show ? <div>{t('Please generate invoices')}</div> : (
              <div>{t('No invoices for this parameters')}</div>)}
            data={show ? store.bulkInvoices : []}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default InvoiceBulkAdd;
