import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorToast, getMessagesForCurrentLanguage, successToast } from '@src/components/wrappers/ToastMessages';
import { getHeaderVersion, handleApiErrorResponse } from '@src/utility/Utils';
import toast from 'react-hot-toast';
import TenantGroup from '@src/views/tenants/types/TenantGroup';
import SelectedOptions from '@src/types/SelectedOptions';
import TenantObject from '@src/views/tenantObjects/types/TenantObject';
import {
  Poll, NewPollPayload, UpdatePollPayload, VotesPerAnswer, SelectedGroupOptions,
  PollAnswerVoter,
} from '../types';

export const getPolls = createAsyncThunk('goKinder/getPolls', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/polls`);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const getPollById = createAsyncThunk(
  'goKinder/getPollById',
  async (id: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_ENV}/polls/${id}`,
      );
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

export const getPollAnswerVotes = createAsyncThunk('goKinder/getPollAnswerVotes', async (id: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/polls/parents/votes/answer/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editPoll = createAsyncThunk('goKinder/editTenantInfo', async (poll: UpdatePollPayload) => {
  try {
    const id = poll?.id;
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/polls/${id}`, poll);
    toast.success('Uspešno izmenjeno.', { position: 'top-right', duration: 3000 });
    return {
      data: response.data,
    };
  } catch (error) {
    handleApiErrorResponse(error);
    throw error;
  }
});

export const addNewPoll = createAsyncThunk('goKinder/addNewPoll', async (poll: NewPollPayload) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/polls`, poll);
    successToast(getMessagesForCurrentLanguage()['New poll added!']);
    return {
      data: response.data,
    };
  } catch (error) {
    handleApiErrorResponse(error);
    throw error;
  }
});

export const deletePoll = createAsyncThunk('goKinder/deletePoll', async (id: string) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/polls/${id}`);
    successToast(getMessagesForCurrentLanguage()['Poll deleted successfully!']);
    return {
      data: { id, ...response.data },
    };
  } catch (error) {
    handleApiErrorResponse(error);
    throw error;
  }
});

export const getAllObjects = createAsyncThunk('goKinder/getAllObjects', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/objects`, { headers: { Version: getHeaderVersion() } });
    return {
      data: response.data,
    };
  } catch (error) {
    handleApiErrorResponse(error);
    throw error;
  }
});

export const getAllGroups = createAsyncThunk('goKinder/getAllGroups', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/tenant-groups`, { headers: { Version: getHeaderVersion() } });
    return {
      data: response.data,
    };
  } catch (error) {
    handleApiErrorResponse(error);
    throw error;
  }
});

// STATISTICS ACTIONS
export const getTotalVotes = createAsyncThunk(
  'goKinder/getTotalVotes',
  async (id: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_ENV}/polls/${id}/votes`,
      );
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

export const getVotesPerAnswerPercentage = createAsyncThunk(
  'goKinder/getVotesPerAnswerPercentage',
  async (id: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_ENV}/polls/${id}/votes/answer/percentage`,
      );
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

export const getVotingPercentage = createAsyncThunk(
  'goKinder/getVotingPercentage',
  async (id: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_ENV}/polls/${id}/voting/percentage`,
      );
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

export const pollsSlice = createSlice({
  name: 'polls',
  initialState: {
    polls: [] as Poll[],
    currentPoll: {} as Poll,
    selectedObjects: [] as SelectedOptions[],
    objectsByTenant: [] as TenantObject[],
    selectedGroups: [] as SelectedGroupOptions[],
    groupsByTenant: [] as TenantGroup[],
    totalVotes: 0,
    votesPerAnswerPercentage: [] as VotesPerAnswer[],
    answerVotes: [] as PollAnswerVoter[],
    votingPercentage: 0,
  },
  reducers: {
    setCurrentPolls: (state, action) => {
      state.currentPoll = action.payload;
    },
    selectedObjects: (state, action) => {
      state.selectedObjects = action.payload;
    },
    selectedGroups: (state, action) => {
      state.selectedGroups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPolls.fulfilled, (state, action) => {
      state.polls = action.payload.data;
    });
    builder.addCase(getPollById.fulfilled, (state, action) => {
      state.currentPoll = action.payload.data;
    });
    builder.addCase(editPoll.fulfilled, (state, action) => {
      const pollIndex = state.polls.findIndex(
        (poll) => poll?.id === action?.payload?.data.id,
      );
      if (pollIndex >= 0) {
        state.polls[pollIndex] = action?.payload?.data;
      }
    });
    builder.addCase(deletePoll.fulfilled, (state, action) => {
      state.polls = state.polls.filter(
        (poll) => poll.id !== action.payload.data.id,
      );
    });
    builder.addCase(getAllObjects.fulfilled, (state, action) => {
      state.objectsByTenant = action.payload.data;
    });
    builder.addCase(getAllGroups.fulfilled, (state, action) => {
      state.groupsByTenant = action.payload.data;
    });
    // STATISTICS
    builder.addCase(getTotalVotes.fulfilled, (state, action) => {
      state.totalVotes = action.payload.data;
    });

    builder.addCase(getVotesPerAnswerPercentage.fulfilled, (state, action) => {
      state.votesPerAnswerPercentage = action.payload.data;
    });
    builder.addCase(getPollAnswerVotes.fulfilled, (state, action) => {
      state.answerVotes = action.payload.data;
    });

    builder.addCase(getVotingPercentage.fulfilled, (state, action) => {
      state.votingPercentage = action.payload.data;
    });
  },
});

export const {
  setCurrentPolls,
  selectedObjects,
  selectedGroups,
} = pollsSlice.actions;

export default pollsSlice.reducer;
