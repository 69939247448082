// ** Reducers Imports
import layout from './layout';
import auth from './authentication';
import modal from './modal';
import navbar from './navbar';
import tenants from '../views/tenants/store';
import parents from '../views/parents/store';
import polls from '../views/polls/store';
import posts from '../views/posts/store';
import passwordManagement from '../views/authentication/store';
import packages from '../views/packages/store';
import tenantObjects from '../views/tenantObjects/store';
import tenantGroups from '../views/tenantGroups/store';
import children from '../views/children/store';
import notes from '../views/notes/store';
import absences from '../views/absences/store';
import albums from '../views/albums/store';
import nonWorkingDays from '../views/nonNonWorkingDays/store';
import recurringNonWorkingDays from '../views/nonNonWorkingDays/store/RecurringNonWorkingDaysStore';
import announcements from '../views/announcements/store';
import invoice from '../views/invoice/store/index';
import payments from '../views/payments/store';
import evaluations from '../views/evaluations/store';
import contract from '../views/contracts/store';
import roles from '../views/roles/store';
import users from '../views/users/store';
import faq from '../views/faq/store';
import cityForms from '../views/cityForms/store';

const rootReducer = {
  auth,
  layout,
  navbar,
  modal,
  tenants,
  parents,
  polls,
  posts,
  passwordManagement,
  packages,
  tenantObjects,
  tenantGroups,
  children,
  notes,
  absences,
  albums,
  nonWorkingDays,
  recurringNonWorkingDays,
  announcements,
  invoice,
  payments,
  evaluations,
  contract,
  roles,
  users,
  faq,
  cityForms,
};

export default rootReducer;
