import { useTranslation } from 'react-i18next';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import Filter from '@src/types/Filter';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import useFilter from '@src/utility/hooks/useFilter';
import ChildrenState from '@src/views/children/types/ChildrenState';
import { useEffect, useMemo, useState } from 'react';
import { AppDispatch } from '@store/store';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { getAllChildren } from '@src/views/children/store';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import RequestStatus from '@src/types/RequestStatus';
import {
  allInvoices,
  deleteInvoice,
  getAllInvoices,
  getInvoiceStatuses,
  getInvoiceTypes,
  getYears,
  selectedChildren,
  selectedInvoiceStatuses,
  selectedInvoiceType,
  selectedYears,
} from '../store';
import InvoiceColumns from '../components/InvoiceColumns';
import InvoiceState from '../types/InvoiceState';

const InvoiceReport = () => {
  const childStore = useSelector((state: ChildrenState) => state.children);
  const invoiceStore = useSelector((state: InvoiceState) => state.invoice);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dateDisabled, setDateDisabled] = useState(false);
  const { onFilterChange, query } = useFilter();
  const navigate = useNavigate();
  const invoicePermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  useEffect(() => {
    dispatch(getInvoiceTypes());
    dispatch(getYears());
    dispatch(getInvoiceStatuses());
    dispatch(getAllChildren());
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    const queryUrl = new URLSearchParams(query);
    const yearId = queryUrl.get('yearId')?.split(',');

    if (yearId) {
      setDateDisabled(true);
    } else if (dateDisabled) {
      setDateDisabled(false);
    }
  }, [query]);

  useEffect(() => {
    dispatch(selectedYears(invoiceStore.years.map(
      (year) => ({ label: String(year.value), value: year.id }),
    )));
  }, [invoiceStore.years]);

  useEffect(() => {
    dispatch(selectedInvoiceStatuses(invoiceStore.invoiceStatuses.map(
      (status) => ({ label: t(`${status}`), value: status }),
    )));
  }, [invoiceStore.invoiceStatuses]);

  useEffect(() => {
    dispatch(selectedChildren(childStore.allChildren.map(
      (child) => ({ label: `${child.name} ${child.surname}`, value: child.id }),
    )));
  }, [childStore.allChildren]);

  useEffect(() => {
    dispatch(selectedInvoiceType(invoiceStore.invoiceTypes.map(
      (type) => ({ label: t(`${type}`), value: type }),
    )));
  }, [invoiceStore.invoiceTypes]);

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Invoice will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteInvoice(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredInvoices = invoiceStore
                .allInvoices.filter((item) => item.id !== id);
              dispatch(allInvoices(filteredInvoices));
              toast.success(t('Invoice successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  const staticFilters: Filter[] = useMemo(() => {
    if (dateDisabled) return [];
    return [{
      label: 'Date from', name: 'startDate', datePicker: 'full-date',
    },
    {
      label: 'Date to', name: 'endDate', datePicker: 'full-date',
    }];
  }, [dateDisabled]);

  const onFilterClick = () => {
    dispatch(getAllInvoices(query))
      .catch(() => { toast.error('Error while fetching objects'); });
  };

  return (
    <div>
      <DataTableWrapper
        exportToExcel="invoice-exports"
        dataSource={invoiceStore.allInvoices}
        title={t('Invoices')}
        subtitle={t('See, manage and filter invoice table')}
        columns={InvoiceColumns({ handleDeleteClick })}
        dataLoaded={dataLoaded}
        onChange={onFilterChange}
        showSearch={false}
        query={query}
        onFilterClick={onFilterClick}
        filters={[{
          label: 'Invoice type', name: 'invoiceType', options: invoiceStore.selectedInvoiceType,
        },
        {
          label: 'Paid status', name: 'paidStatus', options: invoiceStore.selectedInvoiceStatuses,
        },
        {
          label: 'Year', name: 'yearId', options: invoiceStore.selectedYears,
        },
        {
          label: 'Child', name: 'childId', options: invoiceStore.selectedChildren,
        },
        {
          label: 'Reference number', name: 'referenceNumber',
        },
        {
          label: 'Invoice number', name: 'invoiceNumber',
        },
        ...staticFilters,
        ]}
        functionButton={(
          isManagePermissionActive(invoicePermission, 'Invoices')
            ? (
              <Button
                color="primary"
                className="text-nowrap mb-1 mt-2"
                outline
                onClick={() => { navigate('/invoice/bulk/add'); }}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Bulk invoice addition')}</span>
                </div>
              </Button>
            ) : <></>
        )}
      />
    </div>
  );
};

export default InvoiceReport;
