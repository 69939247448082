import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import axios from 'axios';
import Faq from '../types/Faq';
import FaqCategories from '../types/FaqCategories';

export const getAllFaq = createAsyncThunk('goKinder/getAllFaq', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/faq${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const addNewFaq = createAsyncThunk('goKinder/addNewFaq', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/faq`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editFaq = createAsyncThunk('goKinder/editFaq', async ({ id, data }: { id: number, data: any }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/faq/${id}`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteFaq = createAsyncThunk('goKinder/deleteFaq', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/faq/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getAllFaqCategories = createAsyncThunk('goKinder/getAllFaqCategories', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/faq-category${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getFaqCategoriesById = createAsyncThunk('goKinder/getFaqCategoriesById', async (id: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/faq-category/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const addNewFaqCategory = createAsyncThunk('goKinder/addNewFaqCategory', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/faq-category`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editFaqCategory = createAsyncThunk('goKinder/editFaqCategory', async ({ id, data }: { id: number, data: any }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/faq-category/${id}`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteFaqCategory = createAsyncThunk('goKinder/deleteFaqCategory', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/faq-category/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const faqSlice = createSlice({
  name: 'faq',
  initialState: {
    allFaq: [] as Faq[],
    allFaqCategories: [] as FaqCategories[],
    currentFaqCategories: {} as FaqCategories,
  },
  reducers: {
    clearFaq(state) {
      state.allFaq = [];
    },
    setAllFaqCategories(state, action) {
      state.allFaqCategories = action.payload;
    },
    setAllFaq(state, action) {
      state.allFaq = action.payload;
    },
    clearCurrentFaqCategory(state) {
      state.currentFaqCategories = {} as FaqCategories;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFaq.fulfilled, (state, action) => {
      state.allFaq = action.payload.data;
    });
    builder.addCase(getFaqCategoriesById.fulfilled, (state, action) => {
      state.currentFaqCategories = action.payload.data;
    });
    builder.addCase(getAllFaqCategories.fulfilled, (state, action) => {
      state.allFaqCategories = action.payload.data;
    });
  },
});

export const {
  clearFaq,
  setAllFaqCategories,
  setAllFaq,
  clearCurrentFaqCategory,
} = faqSlice.actions;

export default faqSlice.reducer;
