import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import axios from 'axios';
import SelectedOptions from '@src/types/SelectedOptions';
import CityForm from '../types/CityForm';

export const getAllCityForms = createAsyncThunk('goKinder/getAllCityForms', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/city-forms${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const addNewCityForm = createAsyncThunk('goKinder/addNewCityForm', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/city-forms`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editCityForm = createAsyncThunk('goKinder/editCityForm', async ({ id, data }: { id: number, data: any }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/city-forms/${id}`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteCityForm = createAsyncThunk('goKinder/deleteCityForm', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/city-forms/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const cityFormsSlice = createSlice({
  name: 'cityForms',
  initialState: {
    allCityForms: [] as CityForm[],
    selectedTenants: [] as SelectedOptions[],
  },
  reducers: {
    clearCityForms(state) {
      state.allCityForms = [];
    },
    setSelectedTenants(state, action) {
      state.selectedTenants = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCityForms.fulfilled, (state, action) => {
      state.allCityForms = action.payload.data;
    });
  },
});

export const {
  clearCityForms,
  setSelectedTenants,
} = cityFormsSlice.actions;

export default cityFormsSlice.reducer;
