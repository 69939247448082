// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { errorToast, getMessagesForCurrentLanguage, successToast } from '@src/components/wrappers/ToastMessages';
import SelectedOptions from '@src/types/SelectedOptions';
import Parent from '../types/Parent';
import User from '../types/User';

export const getAllParents = createAsyncThunk('goKinder/getParents', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/parents${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const getParentById = createAsyncThunk('goKinder/getParentById', async (id: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/parents/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const addParent = createAsyncThunk('goKinder/addParent', async (parent: Parent) => {
  try {
    delete parent?.id;

    Object.entries(parent).forEach((value) => {
      if (!value[1]) {
        delete parent[value[0] as keyof Parent];
      }
    });

    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/parents`, parent);
    successToast(getMessagesForCurrentLanguage()['Parent successfully added!']);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const editParentInfo = createAsyncThunk('goKinder/editParent', async (parent: Parent) => {
  try {
    const parentId = parent?.id;
    delete parent?.id;
    if (parent.idNumber === 0) {
      delete parent.idNumber;
    }

    Object.entries(parent).forEach((value) => {
      if (!value[1]) {
        delete parent[value[0] as keyof Parent];
      }
    });

    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/parents/${parentId}`, parent);
    successToast(getMessagesForCurrentLanguage()['Parent successfully updated!']);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const deleteParent = createAsyncThunk('goKinder/deleteParent', async (parentId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/parents/${parentId}`);
    successToast(getMessagesForCurrentLanguage()['Parent successfully deleted!']);
    return {
      data: { parentId, ...response.data },
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const createUserAccountForParent = createAsyncThunk('goKinder/createUserAccountForParent', async (id: string) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/parents/${id}/create-user`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

const initialState = {
  allParents: [] as User[],
  currentParent: {} as User,
  selectedObjects: [] as SelectedOptions[],
  selectedGroups: [] as SelectedOptions[],
};

export const parentsSlice = createSlice({
  name: 'parents',
  initialState,
  reducers: {
    updateCurrentParent(state, action) {
      state.currentParent = action.payload;
    },
    setSelectedObjects(state, action) {
      state.selectedObjects = action.payload;
    },
    setSelectedGroups(state, action) {
      state.selectedGroups = action.payload;
    },
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllParents.fulfilled, (state, action) => {
      state.allParents = action.payload.data;
    });
    builder.addCase(getParentById.fulfilled, (state, action) => {
      state.currentParent = action.payload.data;
    });
    builder.addCase(addParent.fulfilled, (state, action) => {
      state.allParents.push(action.payload.data);
    });
    builder.addCase(deleteParent.fulfilled, (state, action) => {
      state.allParents = state.allParents.filter((p) => p.id !== action.payload.data.parentId);
    });
    builder.addCase(editParentInfo.fulfilled, (state, action) => {
      const parentIndex = state.allParents.findIndex((p) => p?.id === action.payload.data.id);
      if (parentIndex >= 0) {
        state.allParents[parentIndex] = {
          ...state.allParents[parentIndex],
          ...action.payload.data,
        };
      }
    });
  },

});

export const {
  updateCurrentParent, setSelectedObjects, setSelectedGroups, clearState,
} = parentsSlice.actions;
export default parentsSlice.reducer;
