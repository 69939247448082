import { yupResolver } from '@hookform/resolvers/yup';
import AddParentValidationSchema from '@src/views/parents/validation/AddParentValidationSchema';
import CustomLabel from '@src/components/forms/CustomLabel';
import { addParent, getAllParents } from '@src/views/parents/store';
import User from '@src/views/parents/types/User';
import { AppDispatch } from '@store/store';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Card, CardBody, Col, Form, Row,
} from 'reactstrap';
import Select, { SingleValue } from 'react-select';
import { selectThemeColors } from '@src/utility/Utils';
import StoreState from '@store/StoreState';
import { useEffect, useRef, useState } from 'react';
import SelectedOptions from '@src/types/SelectedOptions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { successToast } from '@src/components/wrappers/ToastMessages';
import RequestStatus from '@src/types/RequestStatus';
import EditableFields from '@src/views/parents/components/form/EditableFields';
import Switcher from '@src/components/switchers/Switcher';
import ChildrenState from '../types/ChildrenState';
import {
  addParentRelationship, getChildById, setSelectedParents,
} from '../store';

const ParentChildRelationship = () => {
  const location = useLocation();
  const isAddRoute = location.pathname.split('/').pop() === 'add';
  const isSaveAndContinueRef = useRef<boolean>(false);
  const { t } = useTranslation();
  const defaultSelectedParent = { label: t('New parent') ?? 'New', value: 'new parent' };
  const { childId } = useParams();
  const [currentParent, setCurrentParent] = useState(1);
  const [selectedParent, setSelectedParent] = useState<SingleValue<SelectedOptions>>(
    defaultSelectedParent,
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const store = useSelector((state: ChildrenState) => state.children);
  const parentStore = useSelector((state: StoreState) => state.parents);
  const [isContractHolder, setIsContractHolder] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);

  const defaultValues = {
    name: '',
    surname: '',
    email: '',
    embg: '',
    contractNumber: undefined,
    bankAccountNumber: '',
    phoneNumber: '',
    address: '',
    city: '',
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<User>({
    defaultValues,
    resolver: yupResolver(
      AddParentValidationSchema(parentStore.allParents),
    ),
  });

  const handleSuccessfullRelationshipAdd = async () => {
    if (currentParent === 1) {
      reset({});
      setCurrentParent(2);
      if (selectedParent?.value !== 'new parent') {
        successToast(t('First parent added successfully'));
      }
      setSelectedParent(defaultSelectedParent);
    } else if (selectedParent?.value !== 'new parent') {
      successToast(t('Second parent added successfully'));
    }
  };

  const navigateAfterSubmit = () => {
    if (currentParent === 2) {
      if (isSaveAndContinueRef.current) {
        navigate('/children/new');
      } else {
        navigate(`/children/${childId}`);
      }
    }

    if (isAddRoute) {
      navigate(`/children/${childId}`);
    }
  };

  const handleSuccessfulSubmit: SubmitHandler<User> = async (values) => {
    await dispatch(addParent({ ...values, createUserAccount: createAccount ? '1' : '0' }))
      .then(async (data: any) => {
        if (childId && !Object.keys(data).includes('error')) {
          const submitData: any = { childId: +childId, parentId: +data.payload.data.id };
          if (isContractHolder) submitData.contractHolderId = +data.payload.data.id;

          await dispatch(addParentRelationship(submitData)).then((_) => {
            handleSuccessfullRelationshipAdd().then(() => {
              navigateAfterSubmit();
            });
          });
        }
      });
  };

  const onSelectedParentChange = (value: SingleValue<SelectedOptions>) => {
    setSelectedParent(value);
  };

  const onClickHandler = async () => {
    if (selectedParent?.value === 'new parent') {
      handleSubmit(handleSuccessfulSubmit)();
    } else if (childId && selectedParent?.value) {
      const submitData: any = { childId: +childId, parentId: +selectedParent.value };
      if (isContractHolder) submitData.contractHolderId = +selectedParent.value;
      await dispatch(addParentRelationship(submitData))
        .then((data) => {
          if (!Object.keys(data).includes('error')) {
            handleSuccessfullRelationshipAdd().then(() => {
              navigateAfterSubmit();
            });
          }
        });
    }
  };

  useEffect(() => {
    dispatch(getAllParents());
    if (childId) {
      dispatch(getChildById(+childId)).then((data) => {
        if (data.meta.requestStatus === RequestStatus.REQUEST_REJECTED) {
          navigate('/');
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(setSelectedParents(parentStore.allParents.map((parent) => {
      const embg = parent.embg ? `(${parent.embg})` : '';
      return { label: `${parent.name} ${parent.surname} ${embg}`, value: parent.id };
    })));
  }, [parentStore.allParents]);

  useEffect(() => {
    if (!isAddRoute && (store.currentChild.parents?.length ?? 0) > 0) {
      navigate(`/children/${childId}`);
    }
  }, [store.currentChild]);

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Card>
        <CardBody>
          {!isAddRoute ? <h2>{`${t('Parent')} ${currentParent}`}</h2> : <h2>{t('Parent')}</h2>}
          <Row>
            <Col md={12}>
              <CustomLabel name={t('Parent')} />
              <Select
                name="objects"
                className="react-select mb-1"
                classNamePrefix="select"
                theme={selectThemeColors}
                placeholder={t('Select')}
                options={[{ label: t('New parent') ?? 'New', value: 'new parent' }, ...store.selectedParents]}
                onChange={(val) => onSelectedParentChange(val)}
                value={selectedParent}
              />
            </Col>
            {selectedParent?.value === 'new parent'
              && (
                <EditableFields control={control} errors={errors} />
              )}
            <Col md={4} style={{ paddingLeft: '6px' }}>
              <Switcher id="contractHolder" checked={isContractHolder} onChange={() => setIsContractHolder(!isContractHolder)} />
              {t('Mark this parent as contract holder')}
            </Col>
            {selectedParent?.value === 'new parent' ? (
              <Col md={4} style={{ paddingLeft: '6px' }}>
                <Switcher id="contractHolder" checked={createAccount} onChange={() => setCreateAccount(!createAccount)} />
                {t('Create account for a parent')}
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </CardBody>
      </Card>
      <Row className="gy-1 gx-2 mt-75">
        <Col className="text-center mt-1" xs={12}>
          <Button type="button" className="me-1" color="primary" onClick={() => { isSaveAndContinueRef.current = false; onClickHandler(); }}>
            {t('Add')}
          </Button>
          {currentParent === 2 && (
            <Button type="button" className="me-1" color="primary" onClick={() => { isSaveAndContinueRef.current = true; onClickHandler(); }}>
              {t('Add and continue')}
            </Button>
          )}
          <Button type="button" className="me-1" color="primary" onClick={() => navigate(`/children/${childId}`)}>
            {isAddRoute ? t('Cancel') : t('Skip adding parents')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ParentChildRelationship;
