import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import RoleSumbitData from '../types/RoleSubmitData';
import Role from '../types/Role';
import Permission from '../types/Permission';

export const getAllRoles = createAsyncThunk('goKinder/getAllRoles', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/roles${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getRoleById = createAsyncThunk('goKinder/getRoleById', async (id: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/roles/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const createNewRole = createAsyncThunk('goKinder/createNewRole', async (data: RoleSumbitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/roles`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const editRole = createAsyncThunk('goKinder/editRole', async ({ id, data } : { id: number, data: RoleSumbitData }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/roles/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const deleteRole = createAsyncThunk('goKinder/deleteRole', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/roles/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const getAllPermissions = createAsyncThunk('goKinder/getAllPermissions', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/permissions${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    errorToast();
    return Promise.reject(new Error(err));
  }
});

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    allRoles: [] as Role[],
    currentRole: {} as Role,
    allPermissions: [] as Permission[],
  },
  reducers: {
    setAllRoles: (state, action) => {
      state.allRoles = action.payload;
    },
    clearCurrentEvaluation: (state) => {
      state.currentRole = {} as Role;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.allRoles = action.payload.data;
    });
    builder.addCase(getRoleById.fulfilled, (state, action) => {
      state.currentRole = action.payload.data;
    });
    builder.addCase(getAllPermissions.fulfilled, (state, action) => {
      state.allPermissions = action.payload.data;
    });
  },
});

export const {
  setAllRoles,
  clearCurrentEvaluation,
} = rolesSlice.actions;

export default rolesSlice.reducer;
